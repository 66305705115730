export const formatCoinName = (coinName: string | undefined) => {
     if (!coinName || typeof coinName !== "string") {
          return ""; // Return an empty string or handle the case as needed
     }
     return coinName && coinName.slice(0, 6); // Return the first 6 characters
};

export const formatTimestampToDate = (timestamp: number) => {
     const date = new Date(timestamp); // Create a Date object from the timestamp

     // Format the date to 'day month year' format
     const formattedDate = new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "short", // Use 'long' for full month name, 'short' for 3-letter abbreviation
          year: "numeric",
     }).format(date);

     return formattedDate;
};

export const arbitrageFormater = (item: string | undefined) => {
     const formatted = Number(item).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 6,
     });
     return formatted && formatted;
};

// "75.549409" or "75.55" depending on your locale and digits you choose
