import Decimal from "decimal.js";

// Example decimal formatting
const num = new Decimal(-2.5e-7);

// Function to format decimal numbers safely with a fallback value
export const decimalFormater = (num: number | undefined | null): string => {
  // Return fallback value if num is undefined, null, or NaN
  if (num === undefined || num === null || isNaN(num)) {
    return "0.00"; // Fallback value if the number is invalid
  }
  
  const result = new Decimal(num);
  return result.toFixed(10);
};
