import { useTranslation } from "react-i18next";
import styles from "./userOverview.module.css";
import { useEffect, useState } from "react";
import { AllWallets, AssetOverview } from "@/_core/models/walletType";
import { getUserAsserOverview } from "@/_core/services/userService";
import { useSession } from "next-auth/react";
import { getWallets } from "@/_core/services/walletsService";
import Image from "next/image";
import EngagmentNormalItem from "@/components/base/engagementItems/engagmentNormalItem";
import useScreenSize from "@/_core/utils/useScreenSize";
var toFixed = require("tofixed");

const UserOverview = () => {
	const { t } = useTranslation("atwallet");
	const [wallets, setWallets] = useState<AllWallets | null>(null);
	const [assetOverview, setAssetOverview] = useState<AssetOverview | null>(
		null,
	);
	const { data: session } = useSession();
	const [loading, setLoading] = useState(true); // Loading state
	const screenSize = useScreenSize();
	// Function to fetch wallets
	useEffect(() => {
		const fetchWallets = async () => {
			if (session?.access_token) {
				try {
					const response = await getWallets(session.access_token, 0, 10);
					setWallets(response); // Assuming the API response contains a 'wallets' array
				} catch (error) {
					console.error("Failed to fetch wallets:", error);
				} finally {
					setLoading(false); // Set loading to false after fetching
				}
			}
		};
		fetchWallets();
	}, [session]);
	useEffect(() => {
		const fetchOverview = async () => {
			if (session?.access_token) {
				try {
					const response = await getUserAsserOverview(session.access_token);
					setAssetOverview(response); // Assuming the API response contains a 'wallets' array
				} catch (error) {
					console.error("Failed to fetch wallets:", error);
				} finally {
					// Set loading to false after fetching
				}
			}
		};
		fetchOverview();
	}, [session]);
	return (
		<div>
			{wallets && assetOverview && (
				<div className={styles.earnContainer}>
					<h3>{t("text.home.total_balance")}</h3>
					<div className={styles.deposit_withdraw}>
						<div className={styles.total_balance_items}>
							<div className={styles.total_balance_itemIcon}>
								<span className="material-icons">arrow_upward</span>
							</div>
							<div className={styles.engagementItemContent}>
								<span>{t("text.home.deposit")}</span>
								<h5>
									{session?.user?.currency?.symbol
										? `${session.user.currency.symbol} ${toFixed(
												wallets?.totalDeposit.toString(),
												3,
										  )}`
										: ` ${toFixed(wallets?.totalDeposit.toString(), 3)}`}
								</h5>
							</div>
						</div>
						<div className={styles.total_balance_items}>
							<div className={styles.total_balance_itemIcon}>
								<span className="material-icons">arrow_downward</span>
							</div>
							<div className={styles.engagementItemContent}>
								<span>{t("text.home.withdraw")}</span>
								<h5>
									{session?.user?.currency?.symbol
										? `${session.user.currency.symbol} ${toFixed(
												wallets?.totalWithdraw.toString(),
												3,
										  )}`
										: ` ${toFixed(wallets?.totalWithdraw.toString(), 3)}`}
								</h5>
							</div>
						</div>
					</div>
					<h3 className={styles.totalBalance}>
						{session?.user?.currency?.symbol
							? `${session.user.currency.symbol} ${toFixed(
									wallets?.totalBalance,
									5,
							  )}`
							: ` ${toFixed(wallets?.totalBalance, 5)}`}
					</h3>
					<div className={styles.total_btc}>
						<Image
							src="/images/btc.svg"
							width={24}
							height={24}
							alt="bitcoin"
						/>
						<h5>{toFixed(wallets?.totalBtc, 8)}</h5>
					</div>
				</div>
			)}

			{wallets && assetOverview && (
				<div
					className={
						screenSize == "small"
							? styles.assetSummaryCol
							: styles.tabletAssetSummaryCol
					}
				>
					<div
						className={
							screenSize == "small"
								? styles.assetSummaryColItem
								: styles.tabletAssetSummaryColItem
						}
					>
						<EngagmentNormalItem
							currencySymbol={false}
							baseCoinValue={null}
							itemTitle={t("text.home.total_coin")}
							itemValue={assetOverview.totalCoinCount.toString()}
							icon="currency_exchange"
						/>
					</div>
					<div
						className={
							screenSize == "small"
								? styles.assetSummaryColItem
								: styles.tabletAssetSummaryColItem
						}
					>
						<EngagmentNormalItem
							currencySymbol={false}
							baseCoinValue={null}
							itemTitle={t("text.home.open_orders")}
							itemValue={assetOverview.openOrdersCount.toString()}
							icon="currency_exchange"
						/>
					</div>
					<div
						className={
							screenSize == "small"
								? styles.assetSummaryColItem
								: styles.tabletAssetSummaryColItem
						}
					>
						<EngagmentNormalItem
							currencySymbol={false}
							baseCoinValue={null}
							itemTitle={t("text.home.fillded_order")}
							itemValue={assetOverview.closeOrdersCount.toString()}
							icon="currency_exchange"
						/>
					</div>
					<div
						className={
							screenSize == "small"
								? styles.assetSummaryColItem
								: styles.tabletAssetSummaryColItem
						}
					>
						<EngagmentNormalItem
							currencySymbol={true}
							baseCoinValue={null}
							itemTitle={t("text.home.total_commission")}
							itemValue={wallets.totalCommission}
							icon="currency_exchange"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default UserOverview;
