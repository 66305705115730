const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/users/me/coins/favorites`;

export async function getFavoriteCoins(token: string) {
  try {
    if (token) {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response) {
        throw new Error(`HTTP error! status: ${response}`);
      }

      const data = await response.json();
   

      return data; // Return the data to the caller
    } else {
      throw new Error("Access token is missing");
    }
  } catch (error) {
    console.error("Error fetching the data:", error);
    throw error; // Throw the error so the caller can handle it
  }
}
export async function addFavoriteCoins(token: string, coin: number) {


  try {
    if (token) {
      const body = JSON.stringify({ coin: coin }); // Adjust key if necessary
      const response = await fetch(`${apiUrl}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type
        },
        body,
      });

      if (!response.ok) {
        const errorText = await response.text(); // Read error response as text
        console.error("Error details:", errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Try to parse the response if it is JSON
      const contentType = response.headers.get("Content-Type") || "";
      let data;
      if (contentType.includes("application/json")) {
        data = await response.json();
      } else {
        data = await response.text(); // Handle non-JSON responses
      }

  

      return data;
    } else {
      throw new Error("Access token is missing");
    }
  } catch (error) {
    console.error("Error adding the favorite coin:", error);
    throw error;
  }
}

export async function removeFavoriteCoins(token: string, coinId: number) {
  try {
    if (token) {
      const response = await fetch(`${apiUrl}/${coinId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response}`);
      }
      return response; // Return the data to the caller
    } else {
      throw new Error("Access token is missing");
    }
  } catch (error) {
    console.error("Error fetching the data:", error);
    throw error; // Throw the error so the caller can handle it
  }
}
