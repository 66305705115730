import Image from "next/image";
import styles from "./walletCard.module.css";
import React from "react";
import { ExchangeType } from "@/_core/models/exchange_type";
import { useSession } from "next-auth/react";
import { useTranslation } from "react-i18next";
var toFixed = require("tofixed");

interface Wallet {
	name: string;
	totalBalance: number;
	totalBtc: number;
	imgUrl: string;
	owner: string;
	exchange: ExchangeType;
	isActive: boolean;
	isSync: boolean;
}

interface Props {
	index: number;
	selectedIndex: number;
	wallet: Wallet; // Pass the wallet data
}

const WalletCard: React.FC<Props> = ({ selectedIndex, index, wallet }) => {
	const { data: session } = useSession();
	const { t } = useTranslation("atwallet");

	return (
		<div
			className={
				index === selectedIndex
					? styles.card_container_selected
					: styles.card_container
			}
		>
			<div className={styles.card_top}>
				<div className={styles.cardHeader}>
					<div className={styles.account_logo}>
						{/* <span>{t("text.home.wallet_logo")}</span> */}
						<Image
							src={wallet && wallet.exchange.exchangeLogo}
							priority
							width={100}
							height={32}
							alt={wallet.name}
						/>
					</div>
				</div>
				<div className={styles.card_counts}>
					<div className={styles.card_counts_item}>
						<span>{t("text.home.funds")}</span>
						<h3 title={wallet.totalBalance.toLocaleString()}>
							{session?.user?.currency?.symbol
								? `${session.user.currency.symbol} ${toFixed(
										wallet.totalBalance.toLocaleString(),
										3,
								  )}`
								: ` ${toFixed(wallet.totalBalance.toLocaleString(), 2)}`}
						</h3>
					</div>
					<div className={styles.card_counts_item}>
						<span>BTC</span>
						<h5 title={wallet.totalBtc.toLocaleString()}>
							{toFixed(wallet.totalBtc.toLocaleString(), 6)}
						</h5>
					</div>
				</div>
			</div>
			<div className="primaryVerticalLine "></div>
			<div className={styles.card_bottom}>
				{/* <div className={styles.card_owner}>
          <span>{t("text.home.account_owner")}</span>
          <h5>{wallet.owner}</h5>
        </div> */}
				<div className={styles.account_logo}>
					{/*  <span>{t("text.home.wallet_name")}</span> */}
					<h4>{wallet.name}</h4>
				</div>
				<div
					className={
						wallet.isSync
							? styles.card_sync_container_connected
							: styles.card_sync_container_notConnected
					}
				>
					<p>
						{wallet.isSync ? t("text.home.synced") : t("text.home.not_synced")}
					</p>
					<span className="material-icons">
						{wallet.isSync ? "check_circle" : "cancel"}
					</span>
				</div>
			</div>
		</div>
	);
};

export default WalletCard;
