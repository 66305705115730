import { useTranslation } from "react-i18next";
import styles from "./dashboardRightSidbar.module.css";
import { useEffect, useState } from "react";
import { AllWallets, AssetOverview } from "@/_core/models/walletType";
import { getWallets } from "@/_core/services/walletsService";
import { useSession } from "next-auth/react";
import EngagmentNormalItem from "../base/engagementItems/engagmentNormalItem";
import Image from "next/image";
import { getUserAsserOverview } from "@/_core/services/userService";
import Skeleton from "react-loading-skeleton"; // Importing Skeleton
import "react-loading-skeleton/dist/skeleton.css"; // Import CSS for skeleton
var toFixed = require("tofixed");

const DashboardRightSidbar = () => {
     const { t } = useTranslation("atwallet");
     const [wallets, setWallets] = useState<AllWallets | null>(null);
     const [assetOverview, setAssetOverview] = useState<AssetOverview | null>(null);
     const { data: session } = useSession();
     const [loading, setLoading] = useState(true); // Loading state

     // Function to fetch wallets
     useEffect(() => {
          const fetchWallets = async () => {
               if (session?.access_token) {
                    try {
                         const response = await getWallets(session.access_token, 0, 10);
                         setWallets(response); // Assuming the API response contains a 'wallets' array
                    } catch (error) {
                         console.error("Failed to fetch wallets:", error);
                    } finally {
                         setLoading(false); // Set loading to false after fetching
                    }
               }
          };
          fetchWallets();
     }, [session]);

     useEffect(() => {
          const fetchOverview = async () => {
               if (session?.access_token) {
                    try {
                         const response = await getUserAsserOverview(session.access_token);
                         setAssetOverview(response); // Assuming the API response contains a 'wallets' array
                    } catch (error) {
                         console.error("Failed to fetch wallets:", error);
                    } finally {
                         // Set loading to false after fetching
                    }
               }
          };
          fetchOverview();
     }, [session]);

     return (
          <div className={styles.rightBarContainer}>
               {loading ? ( // Conditional rendering based on loading state
                    <>
                         <Skeleton height={150} />
                         <Skeleton count={1} height={250} />
                         <Skeleton height={200} />
                    </>
               ) : (
                    <>
                         {wallets && assetOverview && (
                              <div className={styles.earnContainer}>
                                   <h3>{t("text.home.total_balance")}</h3>
                                   <div className={styles.deposit_withdraw}>
                                        <div className={styles.total_balance_items}>
                                             <div className={styles.total_balance_itemIcon}>
                                                  <span className="material-icons">arrow_upward</span>
                                             </div>
                                             <div className={styles.engagementItemContent}>
                                                  <span>{t("text.home.deposit")}</span>
                                                  <h5>
                                                       {session?.user?.currency?.symbol
                                                            ? `${session.user.currency.symbol} ${toFixed(wallets?.totalDeposit.toString(), 4)}`
                                                            : ` ${toFixed(wallets?.totalDeposit.toString(), 4)}`}
                                                  </h5>
                                             </div>
                                        </div>
                                        <div className={styles.total_balance_items}>
                                             <div className={styles.total_balance_itemIcon}>
                                                  <span className="material-icons">arrow_downward</span>
                                             </div>
                                             <div className={styles.engagementItemContent}>
                                                  <span>{t("text.home.withdraw")}</span>
                                                  <h5>
                                                       {session?.user?.currency?.symbol
                                                            ? `${session.user.currency.symbol} ${toFixed(wallets?.totalWithdraw.toString(), 4)}`
                                                            : ` ${toFixed(wallets?.totalWithdraw.toString(), 4)}`}
                                                  </h5>
                                             </div>
                                        </div>
                                   </div>
                                   <h3 className={styles.totalBalance}>
                                        {session?.user?.currency?.symbol ? `${session.user.currency.symbol} ${toFixed(wallets?.totalBalance, 4)}` : ` ${toFixed(wallets?.totalBalance, 4)}`}
                                   </h3>
                                   <div className={styles.total_btc}>
                                        <Image src="/images/btc.svg" width={24} height={24} alt="bitcoin" />
                                        <h5>{toFixed(wallets?.totalBtc, 9)}</h5>
                                   </div>
                              </div>
                         )}
                         {wallets && assetOverview && (
                              <div className={styles.earnContainer}>
                                   <h3>{t("text.home.asset_summary")}</h3>
                                   <EngagmentNormalItem
                                        currencySymbol={false}
                                        baseCoinValue={null}
                                        itemTitle={t("text.home.total_coin")}
                                        itemValue={assetOverview.totalCoinCount.toString()}
                                        icon="currency_exchange"
                                   />
                                   <EngagmentNormalItem
                                        currencySymbol={false}
                                        baseCoinValue={null}
                                        itemTitle={t("text.home.open_orders")}
                                        itemValue={assetOverview.openOrdersCount.toString()}
                                        icon="currency_exchange"
                                   />
                                   <EngagmentNormalItem
                                        currencySymbol={false}
                                        baseCoinValue={null}
                                        itemTitle={t("text.home.fillded_order")}
                                        itemValue={assetOverview.closeOrdersCount.toString()}
                                        icon="currency_exchange"
                                   />
                                   <EngagmentNormalItem
                                        currencySymbol={true}
                                        baseCoinValue={null}
                                        itemTitle={t("text.home.total_commission")}
                                        itemValue={wallets.totalCommission}
                                        icon="currency_exchange"
                                   />
                              </div>
                         )}
                         {wallets && assetOverview && (
                              <div className={styles.sidebarLastContainer}>
                                   <h3>{t("text.home.total_earning")}</h3>
                                   <div className={styles.earnInfos}>
                                        <div className={styles.earnInfosHead}>
                                             <h1>{assetOverview.profitableCoinCount.toString()}</h1>
                                             <span className="material-icons">trending_up</span>
                                        </div>
                                        <div className={styles.earnInfosAction}>
                                             <span>{t("text.sidebar.earning_coins")}</span>
                                        </div>
                                   </div>
                                   <div className={styles.earnInfos}>
                                        <div className={styles.loseInfosHead}>
                                             <h1>{assetOverview.lossCoinCount.toString()}</h1>
                                             <span className="material-icons">trending_flat</span>
                                        </div>
                                        <div className={styles.loseInfosAction}>
                                             <span>{t("text.sidebar.losing_coins")}</span>
                                        </div>
                                   </div>
                              </div>
                         )}
                    </>
               )}
          </div>
     );
};

export default DashboardRightSidbar;
