import Head from "next/head";
import styles from "@/styles/Home.module.css";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { GetStaticProps } from "next";
import MainLayout from "@/components/layout/mainLayout/main-layout";
import DashboardRightSidbar from "@/components/dashboard/dashboardRightSidbar";
import PopularCoinList from "@/components/dashboard/popularCoinList/popularCoinList";
import DashboardTotalGraph from "@/components/dashboard/dashboardTotalGraph/dashboardTotalGraph";
import FavoriteCoinList from "@/components/dashboard/favoriteCoinList/favoriteCoinList";
import RecentTradesList from "@/components/dashboard/recentTradesList/recentTradesList";
import WalletCardsSlider from "@/components/dashboard/walletCardsSlider/walletCardsSlider";
import { useState, useEffect } from "react";
import AddPortfolioModal from "@/components/addPortfolioModal/addPortfolioModal";
import { ExchangeType } from "@/_core/models/exchange_type";
import ExchanegConnecting from "@/components/addPortfolioModal/exchangeConnecting/exchangeConnecting";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UserOverview from "@/components/mobile/dashboard/userOverview/userOverview";
import useScreenSize from "@/_core/utils/useScreenSize";

export default function Home() {
	const { t } = useTranslation("atwallet");
	const [refetch, setRefetch] = useState(false);
	const [walletRefetch, setWalletRefetch] = useState<boolean>(false);
	const [openAddPortfoliotModal, setOpenAddPortfoliotModal] =
		useState<boolean>(false);
	const [openConnectingExchangeModal, setOpenConnectingExchangeModal] =
		useState<boolean>(false);
	const [selectedExchange, setSelectedExchange] = useState<ExchangeType | null>(
		null,
	);
	const [loading, setLoading] = useState(true);
	const [recentTrades, setRecentTrades] = useState<boolean>(false);
	const [popularCoins, setPopularCoins] = useState<boolean>(false);
	const [favoriteCoins, setFavoriteCoins] = useState<boolean>(true);
	const [showSummeryModal, setShowSummeryModal] = useState<boolean>(false);

	const screenSize = useScreenSize();

	useEffect(() => {
		// Simulating loading state for demonstration
		const timer = setTimeout(() => {
			setLoading(false); // Simulate loading complete after 2 seconds
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<MainLayout>
			<Head>
				<title>{t("text.atwallet")}</title>
				<meta
					name="description"
					content="Generated by create next app"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
				<link
					rel="icon"
					href="/favicon.svg"
				/>
			</Head>

			{screenSize === "large" ? (
				<>
					<main className="pageContainer">
						<div className="pageListsContainer">
							<div className="pageListsHeader">
								<h2>{t("text.home.page_name")}</h2>
								<button
									className="secondaryButton"
									onClick={() => setOpenAddPortfoliotModal(true)}
								>
									<span className="material-icons">add</span>
									{t("text.buttons.add-wallet")}
								</button>
							</div>
							<div className="homeContent">
								<WalletCardsSlider
									walletRefetch={walletRefetch}
									setWalletRefetch={setWalletRefetch}
									setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
								/>
								<div className={styles.tables_container}>
									<div className={styles.tables_culomns}>
										{loading ? (
											// Render skeletons while loading
											<Skeleton
												height={200}
												count={2}
											/>
										) : (
											<>
												<DashboardTotalGraph />
												<RecentTradesList />
											</>
										)}
									</div>
									<div className={styles.tables_culomns}>
										{loading ? (
											// Render skeletons while loading
											<Skeleton
												height={200}
												count={2}
											/>
										) : (
											<>
												<FavoriteCoinList
													refetch={refetch}
													setRefetch={setRefetch}
												/>
												<PopularCoinList setRefetch={setRefetch} />
											</>
										)}
									</div>
								</div>
							</div>
						</div>
						<DashboardRightSidbar />
					</main>

					{openAddPortfoliotModal && (
						<AddPortfolioModal
							walletRefetch={walletRefetch}
							setWalletRefetch={setWalletRefetch}
							setSelectedExchange={setSelectedExchange}
							selectedExchange={selectedExchange}
							openAddPortfoliotModal={openAddPortfoliotModal}
							setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
						/>
					)}
					{openConnectingExchangeModal && selectedExchange && (
						<div className={styles.modal_background}>
							<div className={styles.modal_wrapper}>
								<ExchanegConnecting
									setSelectedExchange={setSelectedExchange}
									selectedExchange={selectedExchange}
									openModal={openConnectingExchangeModal}
									setOpenModal={setOpenConnectingExchangeModal}
									walletRefetch={walletRefetch}
									setWalletRefetch={setWalletRefetch} // Pass the function to refetch wallets
								/>
							</div>
						</div>
					)}
				</>
			) : screenSize === "xMedium" ? (
				<>
					<main className="pageContainer">
						<div className="pageListsContainer">
							<div className="pageListsHeader">
								<h2>{t("text.home.page_name")}</h2>
								<div className="xMediumheadActions">
									<button
										className="noneBackButton"
										onClick={() => setShowSummeryModal(!showSummeryModal)}
									>
										<span className="material-icons">donut_large</span>
									</button>
									<button
										className="secondaryButton"
										onClick={() => setOpenAddPortfoliotModal(true)}
									>
										<span className="material-icons">add</span>
										{t("text.buttons.add-wallet")}
									</button>
								</div>
							</div>
							<div className="homeContent">
								<WalletCardsSlider
									walletRefetch={walletRefetch}
									setWalletRefetch={setWalletRefetch}
									setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
								/>
								<div className={styles.tables_container}>
									<div className={styles.tables_culomns}>
										{loading ? (
											// Render skeletons while loading
											<Skeleton
												height={200}
												count={2}
											/>
										) : (
											<>
												<DashboardTotalGraph />
												<RecentTradesList />
											</>
										)}
									</div>
									<div className={styles.tables_culomns}>
										{loading ? (
											// Render skeletons while loading
											<Skeleton
												height={200}
												count={2}
											/>
										) : (
											<>
												<FavoriteCoinList
													refetch={refetch}
													setRefetch={setRefetch}
												/>
												<PopularCoinList setRefetch={setRefetch} />
											</>
										)}
									</div>
								</div>
							</div>
						</div>
						{showSummeryModal && (
							<div
								onClick={() => setShowSummeryModal(!showSummeryModal)}
								className={styles.modal_background}
							>
								<div className={styles.modal_wrapper}>
									<DashboardRightSidbar />
								</div>
							</div>
						)}
					</main>
				</>
			) : screenSize === "medium" ? (
				<>
					<div className="resPageContainer">
						<div className="pageListsHeader">
							<h3>{t("text.home.page_name")}</h3>
							<button
								className="secondaryButton"
								onClick={() => setOpenAddPortfoliotModal(true)}
							>
								<span className="material-icons">add</span>
								{t("text.buttons.add-wallet")}
							</button>
						</div>
						<WalletCardsSlider
							walletRefetch={walletRefetch}
							setWalletRefetch={setWalletRefetch}
							setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
						/>
						{loading ? (
							<Skeleton
								height={200}
								count={2}
							/>
						) : (
							<UserOverview />
						)}

						<ul className={styles.tradesNavbar}>
							<li
								onClick={() => {
									setPopularCoins(false);
									setFavoriteCoins(true);
									setRecentTrades(false);
								}}
								className={
									favoriteCoins === true
										? styles.activeNavbar
										: styles.notActiveNavbar
								}
							>
								Favorite Coins
							</li>
							<li
								onClick={() => {
									setPopularCoins(true);
									setFavoriteCoins(false);
									setRecentTrades(false);
								}}
								className={
									popularCoins === true
										? styles.activeNavbar
										: styles.notActiveNavbar
								}
							>
								Popular Coins
							</li>
							<li
								onClick={() => {
									setPopularCoins(false);
									setFavoriteCoins(false);
									setRecentTrades(true);
								}}
								className={
									recentTrades === true
										? styles.activeNavbar
										: styles.notActiveNavbar
								}
							>
								Recent Trades
							</li>
						</ul>
						{favoriteCoins && (
							<FavoriteCoinList
								refetch={refetch}
								setRefetch={setRefetch}
							/>
						)}
						{popularCoins && <PopularCoinList setRefetch={setRefetch} />}
						{recentTrades && <RecentTradesList />}
					</div>
					{openAddPortfoliotModal && (
						<AddPortfolioModal
							walletRefetch={walletRefetch}
							setWalletRefetch={setWalletRefetch}
							setSelectedExchange={setSelectedExchange}
							selectedExchange={selectedExchange}
							openAddPortfoliotModal={openAddPortfoliotModal}
							setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
						/>
					)}
					{openConnectingExchangeModal && selectedExchange && (
						<div className={styles.modal_background}>
							<div className={styles.modal_wrapper}>
								<ExchanegConnecting
									setSelectedExchange={setSelectedExchange}
									selectedExchange={selectedExchange}
									openModal={openConnectingExchangeModal}
									setOpenModal={setOpenConnectingExchangeModal}
									walletRefetch={walletRefetch}
									setWalletRefetch={setWalletRefetch} // Pass the function to refetch wallets
								/>
							</div>
						</div>
					)}
				</>
			) : (
				<>
					<div className="resPageContainer">
						<div className="pageListsHeader">
							<h3>{t("text.home.page_name")}</h3>
							<button
								className="secondaryButton"
								onClick={() => setOpenAddPortfoliotModal(true)}
							>
								<span className="material-icons">add</span>
								{t("text.buttons.add-wallet")}
							</button>
						</div>
						<WalletCardsSlider
							walletRefetch={walletRefetch}
							setWalletRefetch={setWalletRefetch}
							setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
						/>
						{loading ? (
							<Skeleton
								height={150}
								count={2}
							/>
						) : (
							<UserOverview />
						)}
						<ul className={styles.tradesNavbar}>
							<li
								onClick={() => {
									setPopularCoins(false);
									setFavoriteCoins(true);
									setRecentTrades(false);
								}}
								className={
									favoriteCoins === true
										? styles.activeNavbar
										: styles.notActiveNavbar
								}
							>
								Favorite Coins
							</li>
							<li
								onClick={() => {
									setPopularCoins(true);
									setFavoriteCoins(false);
									setRecentTrades(false);
								}}
								className={
									popularCoins === true
										? styles.activeNavbar
										: styles.notActiveNavbar
								}
							>
								Popular Coins
							</li>
							<li
								onClick={() => {
									setPopularCoins(false);
									setFavoriteCoins(false);
									setRecentTrades(true);
								}}
								className={
									recentTrades === true
										? styles.activeNavbar
										: styles.notActiveNavbar
								}
							>
								Recent Trades
							</li>
						</ul>
						{favoriteCoins && (
							<FavoriteCoinList
								refetch={refetch}
								setRefetch={setRefetch}
							/>
						)}
						{popularCoins && <PopularCoinList setRefetch={setRefetch} />}
						{recentTrades && <RecentTradesList />}
					</div>
					{openAddPortfoliotModal && (
						<AddPortfolioModal
							walletRefetch={walletRefetch}
							setWalletRefetch={setWalletRefetch}
							setSelectedExchange={setSelectedExchange}
							selectedExchange={selectedExchange}
							openAddPortfoliotModal={openAddPortfoliotModal}
							setOpenAddPortfoliotModal={setOpenAddPortfoliotModal}
						/>
					)}
					{openConnectingExchangeModal && selectedExchange && (
						<div className={styles.modal_background}>
							<div className={styles.modal_wrapper}>
								<ExchanegConnecting
									setSelectedExchange={setSelectedExchange}
									selectedExchange={selectedExchange}
									openModal={openConnectingExchangeModal}
									setOpenModal={setOpenConnectingExchangeModal}
									walletRefetch={walletRefetch}
									setWalletRefetch={setWalletRefetch} // Pass the function to refetch wallets
								/>
							</div>
						</div>
					)}
				</>
			)}
		</MainLayout>
	);
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	return {
		props: {
			...(await serverSideTranslations(locale ?? "en", ["atwallet"])),
		},
	};
};
