const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/trades`;

export async function getTrades(
     token: string,
     offset: number,
     limit: number,
     options?: {
          exchangeId?: number | null;
          coinId?: number | null;
          sort?: string | null;
          side?: string | null;
          type?: string | null;
          status?: string | null;
          isBuyer?: boolean | null;
          startDate?: number | null;
          endDate?: number | null;
     }
) {
     try {
          if (token) {
               let url = `${apiUrl}?offset=${offset}&limit=${limit}`;

               // Append each optional parameter if it’s defined and not null
               if (options) {
                    Object.keys(options).forEach((key) => {
                         const value = options[key as keyof typeof options];
                         if (value !== null && value !== undefined) {
                              url += `&${key}=${value}`;
                         }
                    });
               }

               const response = await fetch(url, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getRecentTrades(token: string, offset: number, limit: number) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}?offset=${offset}&limit=${limit}`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getTradesSummary(token: string) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/summary`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getOrderDetail(token: string, offset: number, limit: number, walletId: number, orderId: string) {
     try {
          if (token) {
               const response = await fetch(` ${apiUrl}?offset=${offset}&limit=${limit}&walletId=${walletId}&orderId=${orderId}`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getCoinDetail(token: string, offset: number, limit: number, exchangeId: number, coinId: number) {
     try {
          if (token) {
               const response = await fetch(` ${apiUrl}?exchangeId=${exchangeId}&coinId=${coinId}&type=TRADE`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}
