import React, { useEffect, useState } from "react";
import Image from "next/image";
import styles from "./popularCoinList.module.css";
import { useSession } from "next-auth/react";
import { getPupularCoins } from "@/_core/services/pupularCoinsService";
import { addFavoriteCoins } from "@/_core/services/favoriteCoinsService";
import { getCurrencySymbol } from "@/components/currency-items";
import { useTranslation } from "react-i18next";
import { createCentrifugeClient } from "@/_core/utils/centrifugeClient";
import { decimalFormater } from "@/_core/utils/decimalFormater";
import { getWssToken } from "@/_core/services/wssToken";

interface Coin {
     id: number;
     name: string;
     code: string;
     icon: string;
     symbol: string;
     exchange: string;
}

interface PopularCoinResponse {
     assets: Coin[];
}

interface Props {
     setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopularCoinList: React.FC<Props> = ({ setRefetch }) => {
     const { t } = useTranslation("atwallet");
     const { data: session } = useSession();
     const [populars, setPopulars] = useState<Coin[]>([]);
     const [wssToken, setWssToken] = useState<string | undefined>();
     const [prices, setPrices] = useState<{ [key: string]: number }>({});
     const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>({});
     const [pricePercentChanges, setPricePercentChanges] = useState<{
          [key: string]: number;
     }>({});
     const [error, setError] = useState<string | null>(null);
     const [screenSize, setScreenSize] = useState<string>("");

     const wsUrl = process.env.NEXT_PUBLIC_API_BASE_WS_URL || "wss://sock-centrifugo.test.raydar.app/connection/websocket";

     useEffect(() => {
          const fetchCoins = async () => {
               if (session?.access_token) {
                    try {
                         const data: PopularCoinResponse = await getPupularCoins(session.access_token);
                         setPopulars(data.assets);
                    } catch (error) {
                         setError(error instanceof Error ? error.message : "An unexpected error occurred");
                    }
               }
          };

          fetchCoins();
     }, [session]);

     useEffect(() => {
          const fetchWssToken = async () => {
               if (session?.access_token) {
                    try {
                         const data = await getWssToken(session.access_token);
                         setWssToken(data.token);
                    } catch (error) {
                         setError(error instanceof Error ? error.message : "An unexpected error occurred");
                    }
               }
          };

          fetchWssToken();
     }, [session]);

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);

     const addToFavoriteHandler = async (coinId: number) => {
          if (session?.access_token) {
               const response = await addFavoriteCoins(session?.access_token, coinId);
               if (response) {
                    setRefetch(true);
               }
          }
     };

     return (
          <div className={styles.list_container}>
               <div className={styles.head_container}>{screenSize === "large" && <h4>{t("text.home.popular_coins")}</h4>}</div>
               <div className={screenSize === "large" ? styles.tables_wrapper : styles.res_tables_wrapper}>
                    <div className={styles.table_header}>
                         <div className={styles.table_header_left}>
                              <span>{t("text.home.coin")}</span>
                              <span>{t("text.home.today_price")}</span>
                              <span>{t("text.home.change")}</span>
                              <span>{t("text.home.change_percent")}</span>
                         </div>
                         <span>{t("text.home.action")}</span>
                    </div>
                    <div className={screenSize === "large" ? styles.tables_body : styles.tables_body}>
                         {populars && populars.length > 0 ? (
                              populars.map((coin) => (
                                   <div key={coin.id} className={styles.table_body}>
                                        <div className={styles.table_body_left}>
                                             <div className={styles.coin_name}>
                                                  <Image src={coin.icon} width={16} height={16} alt={coin.name} />
                                                  <span>{coin.code}</span>
                                             </div>
                                             <span>${decimalFormater(prices[coin.id]) || "0.00"}</span>
                                             <span>${decimalFormater(priceChanges[coin.id]) || "0.00"}</span>
                                             <span>{pricePercentChanges[coin.id] !== undefined ? `${pricePercentChanges[coin.id]}%` : "0.00"}</span>
                                        </div>
                                        <button title="Add to Favorite" onClick={() => addToFavoriteHandler(coin.id)} className={`material-icons ${styles.favorite_button}`}>
                                             star_outline
                                        </button>
                                   </div>
                              ))
                         ) : (
                              <p>No popular coins found.</p>
                         )}
                    </div>
               </div>
          </div>
     );
};

export default PopularCoinList;
