import Image from "next/image";
import styles from "./favoriteCoinList.module.css";
import { useSession } from "next-auth/react";
import { useEffect, useState, useCallback } from "react";
import { getFavoriteCoins, removeFavoriteCoins } from "@/_core/services/favoriteCoinsService";
import { getCurrencySymbol } from "@/components/currency-items";
import { useTranslation } from "react-i18next";
import { getWssToken } from "@/_core/services/wssToken";
import { Centrifuge } from "centrifuge";
import { decimalFormater } from "@/_core/utils/decimalFormater";
import { createCentrifugeClient } from "@/_core/utils/centrifugeClient";

interface Coin {
     id: number;
     name: string;
     code: string;
     icon: string;
}

// Define the type for the response from getPupularCoins
interface FavoriteCoinResponse {
     assets: Coin[];
}

interface Props {
     refetch: boolean;
     setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}
const FavoriteCoinList: React.FC<Props> = ({ refetch, setRefetch }) => {
     const { t } = useTranslation("atwallet");
     const { data: session, status } = useSession();
     const [favorites, setFavorietes] = useState<Coin[]>([]);
     const [error, setError] = useState<string | null>(null);
     const [wssToken, setWssToken] = useState();
     const [screenSize, setScreenSize] = useState<string>("");

     const [prices, setPrices] = useState<{ [key: string]: number }>({});
     const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>({});
     const [pricePercentChanges, setPricePercentChanges] = useState<{
          [key: string]: number;
     }>({});

     const fetchExchanges = useCallback(async () => {
          if (session?.access_token) {
               try {
                    const data: FavoriteCoinResponse = await getFavoriteCoins(session.access_token);
                    setFavorietes(data.assets);
                    setRefetch(false);
               } catch (error) {
                    setError(error instanceof Error ? error.message : "An unexpected error occurred");
               }
          }
     }, [session?.access_token, setRefetch]);

     useEffect(() => {
          if (refetch == true) {
               fetchExchanges();
          }

          fetchExchanges();
     }, [session, fetchExchanges, refetch]);

     const wsUrl = process.env.NEXT_PUBLIC_API_BASE_WS_URL || "wss://sock-centrifugo.test.raydar.app/connection/websocket";

     useEffect(() => {
          const fetchCoins = async () => {
               if (session?.access_token) {
                    try {
                         const data = await getWssToken(session.access_token);
                         setWssToken(data.token);
                    } catch (error) {
                         setError(error instanceof Error ? error.message : "An unexpected error occurred");
                    }
               }
          };

          fetchCoins();
     }, [session]);

     const removeToFavoriteHandler = async (coinId: number) => {
          if (session?.access_token) {
               const response = await removeFavoriteCoins(session?.access_token, coinId);
               if (response) {
                    setRefetch(true);
               }
          }
     };

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);

     return (
          <div className={styles.list_container}>
               <div className={styles.head_container}>{screenSize === "large" && <h4>{t("text.home.favorite_coins")}</h4>}</div>
            
                    <div className={styles.table_header}>
                         <div className={styles.table_header_left}>
                              <span>{t("text.home.coin")}</span>
                              <span>{t("text.home.today_price")}</span>
                              <span>{t("text.home.change")}</span>
                              <span>{t("text.home.change_percent")}</span>
                         </div>
                         <span>{t("text.home.action")}</span>
                    </div>
                    <div className={screenSize === "large" ? styles.tables_wrapper : styles.res_tables_wrapper}>
                         {favorites && favorites.length > 0 ? (
                              favorites.map((coin) => (
                                   <div key={coin.id} className={styles.table_body}>
                                        <div className={styles.table_body_left}>
                                             <div className={styles.coin_name}>
                                                  <Image src={coin.icon} width={16} height={16} alt={coin.name} />
                                                  <span>{coin.code}</span>
                                             </div>
                                             <span>${decimalFormater(prices && prices[coin.id]) || "$0.00"}</span>
                                             <span>${decimalFormater(priceChanges && priceChanges[coin.id]) || "$0.00"}</span>
                                             <span>{pricePercentChanges == undefined ? `${pricePercentChanges[coin.id]}%` : "0.00"}</span>
                                        </div>
                                        <button title="Add to Favorite" onClick={() => removeToFavoriteHandler(coin.id)} className={`material-icons ${styles.favorite_button}`}>
                                             star
                                        </button>
                                   </div>
                              ))
                         ) : (
                              <div className={styles.noData}>
                                   <span className="material-icons">star_outline</span>
                                   <h5>No favorite coins found.</h5>
                              </div>
                         )}
                    </div>
              
          </div>
     );
};

export default FavoriteCoinList;
