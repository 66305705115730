// Define the API URL outside of the function so it's reusable
const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/exchanges`;

// Create the function to fetch wallets
export async function getExchanges(
  token: string,
  offset: number,
  limit: number
) {
  try {
    if (token) {
      const response = await fetch(
        `${apiUrl}?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      return data; // Return the data to the caller
    } else {
      throw new Error("Access token is missing");
    }
  } catch (error) {
    console.error("Error fetching the data:", error);
    throw error; // Throw the error so the caller can handle it
  }
}
