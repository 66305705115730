import Image from "next/image";
import styles from "./recentTradesList.module.css";
import { useEffect, useState } from "react";
import { getRecentTrades } from "@/_core/services/tradeServis";
import { useSession } from "next-auth/react";
import { formatTimestampToDate } from "@/_core/utils/formaters";
import { TradeType } from "@/_core/models/trade_type";
import { useTranslation } from "react-i18next";
var toFixed = require("tofixed");

const RecentTradesList = () => {
     const { data: session, status } = useSession();
     const [recentTrades, setRecentTrades] = useState<TradeType[]>([]); // Ensure state is an array of Coin
     const [error, setError] = useState<string | null>(null);
     const { t } = useTranslation("atwallet");
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          const fetchCoins = async () => {
               if (session?.access_token) {
                    try {
                         // Explicitly type the response from getPupularCoins
                         const data = await getRecentTrades(session.access_token, 0, 10);
                         setRecentTrades(data.transactions); // This will now match the Coin[] type
                    } catch (error) {
                         setError(error instanceof Error ? error.message : "An unexpected error occurred");
                    }
               }
          };

          fetchCoins();
     }, [session]);

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);
     return (
          <div className={styles.list_container}>
               {screenSize === "large" && <h4>{t("text.home.recent_trades")}</h4>}
               <div className={screenSize === "large" ? styles.tables_wrapper : styles.res_tables_wrapper}>
                    <div className={styles.table_header}>
                         <span className={styles.specialSize}>{t("text.home.exchange_coin")}</span>
                         {screenSize === "large" && <span>{t("text.home.units")}</span>}
                         <span> {t("text.home.type")}</span>
                         <span>{t("text.home.price")}</span>
                         <span>{t("text.home.amount")}</span>
                         {screenSize === "large" && <span>{t("text.home.date")}</span>}
                    </div>
                    <div className={screenSize === "large" ? styles.tables_body : styles.tables_body}>
                         {recentTrades ? (
                              recentTrades.map((trade) => {
                                   return (
                                        <div key={trade.id} className={styles.table_body}>
                                             <div className={styles.exchange_coin_wrapper}>
                                                  <Image src={trade.wallet.exchange.exchangeIcon} width={16} height={16} alt={trade.wallet.exchange.name} />
                                                  <div className="primarySmallHr"></div>
                                                  <div className={styles.coin_name}>
                                                       {trade.baseAsset.icon !== "" ? (
                                                            <Image src={trade.baseAsset.icon} width={16} height={16} alt={trade.baseAsset.name} />
                                                       ) : (
                                                            <p>{trade.baseAsset.name}</p>
                                                       )}
                                                       <span>{trade.baseAsset.code}</span>
                                                  </div>
                                             </div>
                                             {screenSize === "large" && <span title={trade.quantity}>{toFixed(trade.quantity, 4)}</span>}
                                             <span className={trade.isBuyer ? styles.buy : styles.sell}>{trade.isBuyer ? t("text.home.buy") : t("text.home.sell")}</span>
                                             <span title={trade.price}>
                                                  {session?.user?.currency?.symbol ? `${session.user.currency.symbol} ${toFixed(trade.price, 4)}` : ` ${toFixed(trade.price, 4)}`}
                                             </span>
                                             <span title={trade.totalPrice}>{toFixed(trade.totalPrice, 4)}</span>
                                             {screenSize === "large" && <span>{formatTimestampToDate(trade.time)}</span>}
                                        </div>
                                   );
                              })
                         ) : (
                              <div className={styles.noTrade}>
                                   <span className="material-icons">currency_exchange</span>
                                   <h5>You have no trade yet</h5>
                              </div>
                         )}
                    </div>
               </div>
          </div>
     );
};

export default RecentTradesList;
