import styles from "./dashboardTotalGraph.module.css";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { getDepositChart } from "@/_core/services/depositChartService";
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useTranslation } from "react-i18next";
import { findLargestDeposit } from "@/_core/utils/graphBigNum";

const DashboardTotalGraph = () => {
     const { data: session, status } = useSession();
     const { t } = useTranslation("atwallet");

     const [depositData, setDepositDate] = useState();
     const [error, setError] = useState<string | null>(null);
     const [borderColor, setBorderColor] = useState("");

     const updateBorderColor = () => {
          const rootStyle = getComputedStyle(document.documentElement);
          const color = rootStyle.getPropertyValue("--border-light").trim();
          setBorderColor(color);
     };
     useEffect(() => {
          updateBorderColor(); // Set the initial border color

          // Observe changes to the 'data-theme' attribute on the <html> element
          const observer = new MutationObserver(() => {
               updateBorderColor(); // Update border color when the theme changes
          });

          observer.observe(document.documentElement, {
               attributes: true,
               attributeFilter: ["data-theme"], // Only observe changes to the 'data-theme' attribute
          });

          return () => observer.disconnect(); // Clean up the observer when the component unmounts
     }, []);

     useEffect(() => {
          const fetchExchanges = async () => {
               if (session?.access_token) {
                    try {
                         const data = await getDepositChart(session.access_token);
                         setDepositDate(data.data);
                    } catch (error) {
                         setError(error instanceof Error ? error.message : "An unexpected error occurred");
                    }
               }
          };

          fetchExchanges();
     }, [session]);
     return (
          <div className={styles.graphMainContainer}>
               <h4>{t("text.home.total_graphs")}</h4>

               <div className={styles.graphContainer}>
                    <div className={styles.chartGuide}>
                         <div className={styles.chartGuideItem}>
                              <span className={styles.chartGuideItemDeposit}></span>
                              <span className={styles.chartGuideItemText}>{t("text.home.deposit")}</span>
                         </div>
                         <div className={styles.chartGuideItem}>
                              <span className={styles.chartGuideItemWithdraw}></span>
                              <span className={styles.chartGuideItemText}>{t("text.home.withdraw")}</span>
                         </div>
                    </div>
                    <LineChart title={t("text.home.total_graphs")} width={800} height={200} data={depositData}>
                         <Line type="monotone" dataKey="deposit" stroke="#10CB8A" />
                         <Line type="monotone" dataKey="withdraw" stroke="#e62864" />
                         <CartesianGrid stroke={borderColor || "#11073B35"} />
                         <XAxis dataKey="name" stroke={borderColor || "#11073B35"} fontSize={8} />
                         <YAxis
                              domain={[0, depositData ? findLargestDeposit(depositData) : 0]} // Fallback to 0 if depositData is undefined
                              stroke={borderColor || "#11073B35"}
                              fontSize={8}
                         />
                    </LineChart>
               </div>
          </div>
     );
};

export default DashboardTotalGraph;
