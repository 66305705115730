import { useEffect, useState, useCallback } from "react";
import styles from "./walletCardsSlider.module.css";
import useEmblaCarousel from "embla-carousel-react";
import WalletCard from "../walletCard/walletCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getWallets } from "@/_core/services/walletsService";
import { useSession } from "next-auth/react";
import { ExchangeType } from "@/_core/models/exchange_type";
import useScreenSize from "@/_core/utils/useScreenSize";

interface Wallet {
     index: number;
     name: string;
     totalBalance: number;
     totalBtc: number;
     imgUrl: string;
     owner: string;
     isActive: boolean;
     isSync: boolean;
     exchange: ExchangeType;
}

interface Props {
     setOpenAddPortfoliotModal: React.Dispatch<React.SetStateAction<boolean>>;
     setWalletRefetch: React.Dispatch<React.SetStateAction<boolean>>;
     walletRefetch: boolean;
}

const WalletCardsSlider: React.FC<Props> = ({ setOpenAddPortfoliotModal, walletRefetch, setWalletRefetch }) => {
     const screenSize = useScreenSize();
     const { data: session } = useSession();
     const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
     const [canScrollPrev, setCanScrollPrev] = useState(false);
     const [canScrollNext, setCanScrollNext] = useState(false);
     const [selectedIndex, setSelectedIndex] = useState(0);
     const [wallets, setWallets] = useState<Wallet[]>([]);
     const [loading, setLoading] = useState(true); // Loading state for skeleton

     const scrollPrev = useCallback(() => {
          if (emblaApi) emblaApi.scrollPrev();
     }, [emblaApi]);

     const scrollNext = useCallback(() => {
          if (emblaApi) emblaApi.scrollNext();
     }, [emblaApi]);

     const onSelect = useCallback(() => {
          if (!emblaApi) return;
          setCanScrollPrev(emblaApi.canScrollPrev());
          setCanScrollNext(emblaApi.canScrollNext());
          setSelectedIndex(emblaApi.selectedScrollSnap());
     }, [emblaApi]);

     const selectCard = useCallback(
          (index: number) => {
               if (emblaApi) {
                    emblaApi.scrollTo(index);
                    setSelectedIndex(index);
               }
          },
          [emblaApi]
     );

     useEffect(() => {
          if (emblaApi) {
               emblaApi.on("select", onSelect);
               onSelect();
          }
     }, [emblaApi, onSelect]);

     useEffect(() => {
          const fetchWallets = async () => {
               setLoading(true); // Start loading
               if (session?.access_token) {
                    try {
                         const response = await getWallets(session.access_token, 0, 50);
                         setWallets(response.wallets);
                         setWalletRefetch(false);
                    } catch (error) {
                         console.error("Failed to fetch wallets:", error);
                    }
               }
               setLoading(false); // Stop loading
          };

          fetchWallets();

          if (walletRefetch) {
               fetchWallets();
          }
     }, [session, walletRefetch, setWalletRefetch]);

     return (
          <div className={styles.embla} ref={emblaRef}>
               <div className={styles.embla__container}>
                    {loading ? (
                         // Skeleton loader
                         Array.from({ length: 5 }).map((_, index) => (
                              <div key={index} className={styles.skeletonCard}>
                                   <Skeleton height={200} width={300} />
                              </div>
                         ))
                    ) : wallets && wallets.length > 0 ? (
                         // Render wallet cards if wallets exist

                         wallets.map((wallet, index) => (
                              <div key={index} className={`${styles.embla__slide} ${selectedIndex === index ? styles.selected : ""}`} onClick={() => selectCard(index)}>
                                   <WalletCard index={index} wallet={wallet} selectedIndex={selectedIndex} />
                              </div>
                         ))
                    ) : (
                         // Render button to add a wallet if no wallets are found
                         <button className={styles.notExistWallet} onClick={() => setOpenAddPortfoliotModal(true)}>
                              <span className="material-icons">add</span>
                              <span> Add wallet</span>
                         </button>
                    )}
               </div>
          </div>
     );
};

export default WalletCardsSlider;
